import request from '@/api/request';
export const yuyueset = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/yuyue' })
export const setjzorder = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/JZpay' });
export const setwjorder = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/wjpay' })

export const getwlbig = () => request({ url: 'https://www.haikoutour.cn/Api/getwlbig', method: 'get' })
export const getsdtitle = () => request({ url: 'https://www.haikoutour.cn/Api/getsdtitle', method: 'get' })
export const getsdlbt = () => request({ url: 'https://www.haikoutour.cn/Api/getsdlbt', method: 'get' })
export const getsdgg = () => request({ url: 'https://www.haikoutour.cn/Api/getsdgg', method: 'get' })
export const getsdlbttext = () => request({ url: 'https://www.haikoutour.cn/Api/getsdlbttext', method: 'get' })
export const getsdjrsd = () => request({ url: 'https://www.haikoutour.cn/Api/getsdjrsd', method: 'get' })
export const getsdsdgd = () => request({ url: 'https://www.haikoutour.cn/Api/getsdsdgd', method: 'get' })
export const getsdxwfb = () => request({ url: 'https://www.haikoutour.cn/Api/getsdxwfb', method: 'get' })

export const getsdyw = () => request({ url: 'https://www.haikoutour.cn/Api/getsdyw', method: 'get' })

export const getsdtj = () => request({ url: 'https://www.haikoutour.cn/Api/getsdtj', method: 'get' })

export const getsdzxt = () => request({ url: 'https://www.haikoutour.cn/Api/getsdzxt', method: 'get' })

export const getsdeng = () => request({ url: 'https://www.haikoutour.cn/Api/getsdeng', method: 'get' })
export const getsdengt = () => request({ url: 'https://www.haikoutour.cn/Api/getsdengt', method: 'get' })

export const getsdcj = () => request({ url: 'https://www.haikoutour.cn/Api/getsdcj', method: 'get' })
export const getsddwjl = () => request({ url: 'https://www.haikoutour.cn/Api/getsddwjl', method: 'get' })
export const getsdjy = () => request({ url: 'https://www.haikoutour.cn/Api/getsdjy', method: 'get' })
export const getsdly = () => request({ url: 'https://www.haikoutour.cn/Api/getsdly', method: 'get' })
export const getsdwy = () => request({ url: 'https://www.haikoutour.cn/Api/getsdwy', method: 'get' })
export const getsdqc = () => request({ url: 'https://www.haikoutour.cn/Api/getsdqc', method: 'get' })
export const getsdfc = () => request({ url: 'https://www.haikoutour.cn/Api/getsdfc', method: 'get' })