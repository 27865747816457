<template>
  <div style="height: 100%;
  width:100%;
  
    margin: 0 auto; margin: auto;font-family: '微软雅黑';position: relative; ">

    <!-- 头部 -->
    <div style=" width: 100%;
  height: 35px;
  background: #f4f4f4;
  margin: 0px auto;
  padding: 0px;">
      <div class="top">
        <div class="tophome"><a href="http://www.sdchina.com/" target="_blank">海南网</a><span
            class="toptxt1">|</span>官方网站</div>
  
        
        <!-- <form id="fromTop" action="http://search.sdchina.com/do.aspx" method="post" target="_blank">
          <ul class="search">
            <li class="searcha"><a>搜索</a>
              <ul class="searchb">
                <li>
                  <input type="text" name="title" id="title" class="intex2">
                </li>
                <li>
                  <input type="submit" name="submit" id="submit" value="搜索" class="intex3" onclick="searchTop()">
                </li>
              </ul>
            </li>
          </ul>
        </form> -->
      </div>
    </div>
    <div class="">
      <div class="header">
        <div class="logo">
          <h1><a href="/"><img src="https://pic.imgdb.cn/item/6129da1544eaada7396af6af.png" style="width: 200px;height: 100px;"></a></h1>
        </div>
        <!-- <div class="language"><a class="enn" href="http://english.sdchina.com" target="_blank">English</a>|<a class="kr"
            href="http://kr.sdchina.com" target="_blank">한국어</a>|<a class="jp last" href="http://jp.sdchina.com"
            target="_blank">日本語</a></div> -->
            
      </div>
      <div class="">
      </div>
      <div class="" style="padding: 0">
        <div class="">
        </div>
        <div class="menu">
          <ul>
            <li class="first">
              <p><a href="http://news.sdchina.com/" target="_blank">新闻</a></p>
              <p><a href="http://finance.sdchina.com/" target="_blank">财经</a></p>
            </li>
            <li class="line"></li>
            <li>
              <p><a href="http://video.sdchina.com/" target="_blank">视频</a></p>
              <p><a href="http://photo.sdchina.com/" target="_blank">图说</a></p>
            </li>
            <li class="line"></li>
            <li>
              <p><a href="http://theory.sdchina.com/" target="_blank">理论</a></p>
              <p><a href="http://news.sdchina.com/minsheng/" target="_blank">民声</a></p>
            </li>
            <li class="line"></li>
            <li>
              <p><a href="http://auto.sdchina.com/" target="_blank">汽车</a></p>
              <p><a href="http://house.sdchina.com/" target="_blank">房产</a></p>
            </li>
            <li class="line"></li>
            <li>
              <p><a href="http://edu.sdchina.com/" target="_blank">教育</a></p>
              <p><a href="http://tour.sdchina.com/" target="_blank">旅游</a></p>
            </li>
            <li class="line"></li>
            <li class="three">
              <p><a href="http://ytlj.sdchina.com/" target="_blank">图解</a></p>
              <p><a href="http://zhibo.sdchina.com/" target="_blank">直播</a></p>
            </li>
            <li class="line"></li>
            <li class="three">
              <p><a href="http://sdio.sdchina.com/" target="_blank">发布</a></p>
              <p><a href="http://special.sdchina.com/" target="_blank">专题</a></p>
            </li>
            <li class="line"></li>
            <li class="three">
              <p><a href="http://www.sdphoto.com.cn/" target="_blank">图片</a></p>
              <p class="qqqq"><a href="http://sdfe.sdchina.com/" target="_blank">交流</a></p>
            </li>
            <li class="line"></li>
           
          </ul>
          <!-- <div style="width: 300px;height: 28px;background-color: #ffffff;display: flex;padding-left: 10px;box-sizing: border-box;align-items: center;border: 1px solid #ccc;border-radius: 3px;position:absolute;left: 20px;top: 10px;">
    <input type="text" style="width: 100%;box-shadow: none; outline: none;height: 23px;border: none;"><span class="el-icon-search" @click="inputHanlder"></span>
    </div> -->
        </div>

        <div class="wnd" style="margin: 0 auto;">
          <!-- <div class="wnda">
            <ul>
              <li><a href="https://tousu.www.gov.cn/dc/index.htm" target="_blank">国务院互联网+督查</a></li>
              <li><a href="http://news.sdchina.com/special/touchsd2017/touchlist.aspx" target="_blank">感知山东体验活动</a></li>
              <li><a href="http://news.sdchina.com/special/2023/wlzgj/" target="_blank">网络中国节</a></li>
              <li><a href="http://news.sdchina.com/special/2023/whlc/" target="_blank">文化“两创”看山东</a></li>
            </ul>
          </div> -->
          <div class="wndb" style="width: 1140px;height: 100px;">
            <div class="wndb2" style="width: 1140px;height: 100px;">
              <div class="ads-box ads-text" style="width: 1140px;height: 100px;">
                <div class="ad_0216" style="display: none;">
                  <a href="http://news.sdchina.com/special/2023/dzx/" target="_blank">
                    <img src="https://s1.ax1x.com/2023/06/29/pC0M5nO.png"></a>
                </div>
                <div class="ad_0216" style="width: 1140px;height: 100px;display: block;">
                  <a href="http://2023.shandong.sdchina.com/" target="_blank">
                    <img src="https://s1.ax1x.com/2023/06/29/pC0M5nO.png" style="width: 1140px;height: 100px;"></a>
                </div>
              </div>


            </div>
            <!-- <div class="wndb1">
              <a href="http://video.sdchina.com/list/121.html" target="_blank">外眼看山东</a><a
                href="http://www.piyao.org.cn/xxjbrk.htm" target="_blank">中国互联网联合辟谣平台</a><a
                href="http://m.sdchina.com/touch/special/4775950.html" target="_blank">“歪果仁”说节气</a><a
                href="https://sd.xuexi.cn" target="_blank">“学习强国”山东学习平台</a>
            </div> -->
          </div>
         
        </div>

      </div>
    </div>
    <div class="title">
      <h3 style="font-size:23px;"><a target="_blank" :href="title.a"
          title="镜观·领航 | 关于共青团工作，总书记这样嘱托">{{ title.title }}</a></h3>

      <p>
        <a target="_blank" :href="title.a1" :title="title.text1">{{ title.text1 }}</a>
        <span>|</span> <a target="_blank" :href="title.a2" :title="title.text2">{{ title.text2 }}</a>
        <span>|</span> <a target="_blank" :href="title.a3" :title="title.text3">{{ title.text3 }}</a>

      </p>
    </div>



    <div class="w">
      <div class="maina">

        <div id="fsD1" class="focus">

          <div class="fbg">
            <div class="D1fBt" id="D1fBt">
              <swiper></swiper>
            </div>
          </div>
          <span class="prev"></span><span class="next"></span>
        </div>


        <div class="mainab">
          <ul>
            <li>
              <h3><a :href="lbttext.a1" target="_blank"><img
                    :src="lbttext.url1"></a>
              </h3>
              <p><a :href="lbttext.a1" target="_blank">{{ lbttext.text1 }}</a></p>
            </li>
            <li>
              <h3><a :href="lbttext.a2" target="_blank"><img
                :src="lbttext.url2"></a></h3>
              <p><a  :href="lbttext.a2" target="_blank">世界互联网大会数字文明尼山对话
                </a></p>
            </li>
          </ul>
        </div>
      </div>
      <div class="mainb">
        <h2>
          <a target="_blank" :href="gg.a1" :title="gg.titles">{{ gg.titles }}</a>
        </h2>
        <h3><a target="_blank" :href="gg.as1"
            :title="gg.texts1">{{ gg.texts1 }}</a></h3>
        <h3><a target="_blank" :href="gg.as2"
            :title="gg.texts2">{{ gg.texts2 }}</a></h3>

        <h4><a target="_blank" :href="gg.as3"
            :title="gg.texts3">{{ gg.texts3 }}</a></h4>
        <h4><a target="_blank" :href="gg.as4"
            :title="gg.texts4">{{ gg.texts4 }}</a></h4>

        <p><a target="_blank" :href="gg.as5"
            :title="gg.texts5">{{ gg.texts5 }}</a></p>
        <p><a target="_blank" :href="gg.as6"
            :title="gg.texts6">{{ gg.texts6 }}</a></p>

        <h5></h5>
        <h2>
          <a target="_blank" :href="gg.a2">{{ gg.titlex }}</a>
        </h2>
        <h3><a target="_blank" :href="gg.ax1"
            :title="gg.textx1">{{ gg.textx1 }}</a></h3>
        <h3><a target="_blank" :href="gg.ax2"
            :title="gg.textx2">{{ gg.textx2 }}</a></h3>

        <h4><a target="_blank" :href="gg.ax3"
            :title="gg.textx3">{{ gg.textx3 }}</a></h4>
        <h4><a target="_blank" :href="gg.ax4"
            :title="gg.textx4">{{ gg.textx4 }}</a></h4>

        <p><a target="_blank" :href="gg.ax5"
            :title="gg.textx5">{{ gg.textx5 }}</a></p>
        <p><a target="_blank" :href="gg.ax6"
            :title="gg.textx6">{{ gg.textx6 }}</a></p>

      </div>
    </div>

    <div class="bg2">
      <div class="w">
        <div class="mainc">
          <div class="mainca">
            <h3>
              <a href="" target="_blank">今日海南</a>
            </h3>
          </div>
          <div class="maincb">
            <ul>
              <li><a target="_blank" :href="jrsd.a1"
                  :title="jrsd.text1">{{ jrsd.text1 }}</a></li>
              <li><a target="_blank" :href="jrsd.a2"
                  :title="jrsd.text2">{{ jrsd.text2 }}</a></li>
              <li><a target="_blank" :href="jrsd.a3"
                  :title="jrsd.text3">{{ jrsd.text3 }}</a></li>
              <li><a target="_blank" :href="jrsd.a4"
                  :title="jrsd.text4">{{ jrsd.text4 }}</a></li>

            </ul>
          </div>
          <div class="maincc">
            <ul>
              <li><a target="_blank" :href="jrsd.a5"
                  :title="jrsd.text5">{{ jrsd.text5 }}</a></li>
              <li><a target="_blank" :href="jrsd.a6"
                  :title="jrsd.text6">{{ jrsd.text6 }}</a></li>
              <li><a target="_blank" :href="jrsd.a7"
                  :title="jrsd.text7">{{ jrsd.text7 }}</a></li>
              <li><a target="_blank" :href="jrsd.a8"
                  :title="jrsd.text8">{{ jrsd.text8 }}</a></li>

            </ul>
          </div>
        </div>
        <div class="mainc">
          <div class="mainca">
            <h3>
              <a href="" target="_blank">海南各地</a>
            </h3>
          </div>
          <div class="maincb">
            <ul>
              <li><a target="_blank" :href="sdgd.a1"
                  :title="sdgd.text1">{{ sdgd.text1 }}</a></li>
              <li><a target="_blank" :href="sdgd.a2"
                  :title="sdgd.text2">{{ sdgd.text2 }}</a></li>
              <li><a target="_blank" :href="sdgd.a3"
                  :title="sdgd.text3">{{ sdgd.text3 }}</a></li>
              <li><a target="_blank" :href="sdgd.a4"
                  :title="sdgd.text4">{{ sdgd.text4 }}</a></li>

            </ul>
          </div>
          <div class="maincc">
            <ul>
              <li><a target="_blank" :href="sdgd.a5"
                  :title="sdgd.text5">{{ sdgd.text5 }}</a></li>
              <li><a target="_blank" :href="sdgd.a6"
                  :title="sdgd.text6">{{ sdgd.text6 }}</a></li>
              <li><a target="_blank" :href="sdgd.a7"
                  :title="sdgd.text7">{{ sdgd.text7 }}</a></li>
              <li><a target="_blank" :href="sdgd.a8"
                  :title="sdgd.text8">{{ sdgd.text8 }}</a></li>

            </ul>
          </div>
        </div>
        <div class="maind">
          <div class="mainda">
            <a href="http://sdio.sdchina.com/">
              <img src="https://s1.ax1x.com/2023/06/29/pC0MzDS.jpg"></a>
          </div>
          <div class="maindb">
            <ul>
              <li><a :href="xwfb.a1" target="_blank">{{xwfb.text1}}</a></li>
              <li><a :href="xwfb.a1" target="_blank">{{xwfb.text1}}</a></li>

            </ul>
          </div>
      
        </div>
      </div>
    </div>
    <div class="w">
      <div class="maine">
        <div class="mainee">
          <h3>
            <a href="" target="_blank">要闻</a>
          </h3>
        </div>
        <div class="mainea">
          <h3><a target="_blank" :href="yw.a" :title="yw.title"><img
                :src="yw.url"></a></h3>
          <p><a :href="yw.a" target="_blank">{{ yw.title }}</a></p>

        </div>
        <div class="maineb">
          <ul>
            <li><a target="_blank" :href="yw.a1"
                :title="yw.text1">{{yw.text1}}</a></li>
            <li><a target="_blank" :href="yw.a2"
                :title="yw.text2">{{yw.text2}}</a></li>
            <li><a target="_blank" :href="yw.a3"
                :title="yw.text3">{{yw.text3}}</a></li>
            <li><a target="_blank" :href="yw.a4"
                :title="yw.text4">{{yw.text4}}</a></li>

          </ul>
        </div>
        <div class="mainec">
          <ul>
            <li><a target="_blank" :href="yw.a5"
                :title="yw.text5">{{yw.text5}}</a></li>
            <li><a target="_blank" :href="yw.a6"
                :title="yw.text6">{{yw.text6}}</a></li>
            <li><a target="_blank" :href="yw.a7"
                :title="yw.text7">{{yw.text7}}</a></li>
            <li><a target="_blank" :href="yw.a8"
                :title="yw.text8">{{yw.text8}}</a></li>
            <li><a target="_blank" :href="yw.a9"
                :title="yw.text9">{{yw.text9}}</a></li>

          </ul>
        </div>
        <div class="mained">
          <ul>
            <li><a target="_blank" :href="yw.a10"
                :title="yw.text10">{{yw.text10}}</a></li>
            <li><a target="_blank" :href="yw.a11"
                :title="yw.text11">{{yw.text11}}</a></li>
            <li><a target="_blank" :href="yw.a12"
                :title="yw.text12">{{yw.text12}}</a></li>
            <li><a target="_blank" :href="yw.a13"
                :title="yw.text13">{{yw.text13}}</a></li>
            <li><a target="_blank" :href="yw.a14"
                :title="yw.text14">{{yw.text14}}</a></li>

          </ul>
        </div>
      </div>
      <div class="mainf">
        <div class="mainfa">
          <h3>
            <a href="" target="_blank">图解</a>
          </h3>
        </div>
        <div class="mainfb">
          <div class="mainfbb">
            <ul>
              <li><a :href="tj.a1" target="_blank"><img
                    :src="tj.url1"></a></li>
              <li><a :href="tj.a2" target="_blank"><img
                    :src="tj.url2"></a></li>

            </ul>
          </div>
        </div>
        <div class="mainfc">
          <ul>
            <li><a :href="tj.at1" target="_blank">{{ tj.text1 }}</a></li>
            <li><a :href="tj.at2" target="_blank">{{ tj.text2 }}</a></li>

          </ul>
        </div>
      </div>
    </div>
    <div
      style="width: 1200px;height: 80px;margin: auto;margin-top: 20px; display: flex;justify-content: space-between;position: relative;overflow: hidden;">
      <div style="width: 285px;height: 80px;" @mouseover="a=0">
        <img :src="zxt.x1" alt="" style="width: 285px;height: 80px;">
      </div>
      <div style="width: 285px;height: 80px;" @mouseover="b = 0">
        <img :src="zxt.x2" alt="" style="width: 285px;height: 80px;">
      </div>
      <div style="width: 285px;height: 80px;" @mouseover="c = 0">
        <img :src="zxt.x3" alt="" style="width: 285px;height: 80px;">
      </div>
      <div style="width: 285px;height: 80px;" @mouseover="d = 0">
        <img :src="zxt.x4" alt="" style="width: 285px;height: 80px;">
      </div>
      <div style="width: 1200px;height: 80px;position: absolute;" :class="a == 0 ? 'showa' : 'showa2'" @mouseout="a = 0">
        <a :href="zxt.ax1">
          <img :src="zxt.d1" alt="">

        </a>
      </div>
      <div style="width: 1200px;height: 80px;position: absolute;" :class="b == 0 ? 'showb' : 'showb2'" @mouseout="b = 0">
        <a :href="zxt.ax2">
          <img :src="zxt.d2" alt="">

        </a>
      </div>
      <div style="width: 1200px;height: 80px;position: absolute;" :class="c == 0 ? 'showc' : 'showc2'" @mouseout="c = 0">
        <a :href="zxt.ax3">
          <img :src="zxt.d3" alt="">

        </a>
      </div>
      <div style="width: 1200px;height: 80px;position: absolute;" :class="d == 0 ? 'showc' : 'showc2'" @mouseout="d = 0">
        <a :href="zxt.ax4">
          <img :src="zxt.d4" alt="">

        </a>
      </div>
    </div>

    <div class="eng">
      <div class="enga">
        <h3 class="enn">
          <a href="" target="_blank">English</a>
        </h3>
        <h4 class="en">Witness the real Shandong</h4>
      </div>
      <div class="engb en">
        <ul>
          <li>
            <h3><a target="_blank" :href="eng.a"><img
                  :src="eng.url"></a></h3>
            <p><a target="_blank" :href="eng.a1">{{ eng.text1 }}</a></p>
          </li>
          <li>
            <h3><a target="_blank" :href="engt.a"><img
                  :src="engt.url"></a></h3>
            <p><a target="_blank" :href="engt.a1">{{ engt.text1 }}
            </a></p>
          </li>

        </ul>
      </div>
      <div class="engc en">
        <ul>
          <li><a target="_blank" :href="eng.a2">{{ eng.text2 }}
            </a></li>
          <li><a target="_blank" :href="engt.a2">{{ engt.text2 }}
            </a></li>
          <li><a target="_blank" :href="eng.a3">{{ eng.text3 }}
            </a></li>
          <li><a target="_blank" :href="engt.a3">{{ engt.text3 }}
            </a></li>
          <li><a target="_blank" :href="eng.a4">{{ eng.text4 }}
            </a></li>
          <li><a target="_blank" :href="engt.a4">{{ engt.text4 }}
            </a></li>

        </ul>
      </div>
      <div class="engd en">
        <ul>
          <li><a target="_blank" :href="eng.a5">{{ eng.text5 }}
            </a></li>
          <li><a target="_blank" :href="engt.a5">{{ engt.text5 }}
            </a></li>
          <li><a target="_blank" :href="engt.a5">{{ engt.text5 }}
            </a></li>
          <li><a target="_blank" :href="engt.a6">{{ engt.text6 }}
            </a></li>

        </ul>
      </div>
    </div>
    <div class="w" style="height:360px">
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">财经</a>
          </h3>
        </div>
        <div class="mainnb">
          <h3><a :href="cj.a" target="_blank" :title="cj.text1"><img
                :src="cj.url" :alt="cj.text1"></a></h3>
          <p><a :href="cj.a1" target="_blank"
              :title="cj.text1">{{ cj.text1 }}</a></p>

        </div>
        <div class="mainnc">
          <ul>
            <li><a :href="cj.a2" target="_blank"
                :title="cj.text2">{{ cj.text2 }}</a></li>
            <li><a :href="cj.a3" target="_blank"
                :title="cj.text3">{{ cj.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>
            <li><a :href="cj.a4" target="_blank"
                :title="cj.text4">{{ cj.text4 }}</a></li>
            <li><a href="http://news.sdchina.com/show/4815545.html" target="_blank"
                title="税惠政策效能释放 我国持续做强养老“第三支柱”">税惠政策效能释放 我国持续做强养老“第三支柱111”</a></li>

          </ul>
        </div>
      </div>
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">对外交流</a>
          </h3>
        </div>
        <div class="mainnb">
          <h3><a :href="dwjl.a" target="_blank" :title="dwjl.text1"><img
                :src="dwjl.url" :alt="dwjl.text1"></a>
          </h3>
          <p><a :href="dwjl.a2" target="_blank"
                :title="dwjl.text2">{{ dwjl.text1 }}</a></p>

        </div>
        <div class="mainnc">
          <ul>
            <li><a :href="dwjl.a2" target="_blank"
                :title="dwjl.text2">{{ dwjl.text2 }}</a></li>
            <li><a :href="dwjl.a3" target="_blank"
                :title="dwjl.text3">{{ dwjl.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>
            <li><a :href="dwjl.a4" target="_blank"
                :title="dwjl.text4">{{ dwjl.text4 }}</a></li>
            <li><a href="http://sdfe.sdchina.com/show/4815033.html" target="_blank"
                title="外国留学生“邂逅”端午节体验中华传统文化">外国留学生“邂逅”端午节体验中华传统文化</a></li>

          </ul>
        </div>
      </div>
      <div class="maino">
        <div class="mainoa">
          <h3>
            <a href="http://news.sdchina.com/minsheng/" target="_blank" style="font-size: 24px;">
              海南民生</a>
          </h3>
          <h4>
            <a href="http://news.sdchina.com/minsheng/post.html" target="_blank">
              </a>
          </h4>
        </div>
        <div class="mainob">
          <ul>


            <li><span><a title="" href=""
                  target="_blank"></a></span><a href="" target="_blank"
                title="身份证补办期间能否领取临时身份证?">身份证补办期间能否领取临时身份证?</a></li>

            <li><span><a title="" href=""
                  target="_blank"></a></span><a href="" target="_blank"
                title="开办备案制中医诊所需满足哪些条件?">开办备案制中医诊所需满足哪些条件?</a></li>

            <li><span><a title="" href=""
                  target="_blank"></a></span><a href="" target="_blank"
                title="驾驶证到期应怎样更换？">驾驶证到期应怎样更换？</a></li>

            <li><span><a title="" href=""
                  target="_blank"></a></span><a href="" target="_blank"
                title="咨询如何线上下载营业执照?">咨询如何线上下载营业执照?</a></li>

            <li><span><a title="" href=""
                  target="_blank"></a></span><a href="" target="_blank"
                title="个体工商户在政务大厅刻制公章是否收费?">个体工商户在政务大厅刻制公章是否收费?</a></li>

          </ul>
        </div>
      </div>
    </div>

    <div class="w">
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">教育</a>
          </h3>
        </div>
        <div class="mainnb">
          <h3><a :href="jy.a" target="_blank" :title="jy.text1"><img
                :src="jy.url" :alt="jy.text1"></a></h3>
          <p><a :href="jy.a1" target="_blank"
              :title="jy.text1">{{ jy.text1 }}</a></p>

        </div>
        <div class="mainnc">
          <ul>
            <li><a :href="jy.a2" target="_blank"
              :title="jy.text2">{{ jy.text2 }}</a></li>
            <li><a :href="jy.a3" target="_blank"
              :title="jy.text3">{{ jy.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>
            <li><a :href="jy.a4" target="_blank"
              :title="jy.text4">{{ jy.text4 }}</a></li>
            <li><a :href="jy.a5" target="_blank"
              :title="jy.text5">{{ jy.text5 }}</a></li>

          </ul>
        </div>
      </div>
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">旅游</a>
          </h3>
        </div>
        <div class="mainnb">
          <h3><a :href="ly.a" target="_blank"><img
                :src="ly.url" :alt="ly.text1"></a></h3>
          <p><a :href="ly.a1" target="_blank" :title="ly.text1">{{ ly.text1 }}</a></p>

        </div>
        <div class="mainnc">
          <ul>
            <li><a :href="ly.a2" target="_blank" :title="ly.text2">{{ ly.text2 }}</a></li>
            <li><a :href="ly.a3" target="_blank" :title="ly.text3">{{ ly.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>
            <li><a :href="ly.a4" target="_blank" :title="ly.text4">{{ ly.text4 }}</a></li>
            <li><a :href="ly.a5" target="_blank" :title="ly.text5">{{ ly.text5 }}</a></li>

          </ul>
        </div>
      </div>
      <div class="mainp">
        <div class="mainpa">
          <h3>
            <a href="" target="_blank">文娱</a>
          </h3>
        </div>
        <div class="mainpb">
          <h3><a :href="wy.a" target="_blank"><img
                :src="wy.url" :alt="wy.text1"></a></h3>
          <p><a :href="wy.a1" :title="wy.text1" target="_blank">{{ wy.text1 }}</a></p>

        </div>
        <div class="mainpc">
          <ul>
            <li><a :href="wy.a2" :title="wy.text2" target="_blank">{{ wy.text2 }}</a></li>
            <li><a :href="wy.a3" :title="wy.text3" target="_blank">{{ wy.text3 }}</a>
            </li>

          </ul>
        </div>
        <div class="mainpd">
          <ul>
            <li><a :href="wy.a4" :title="wy.text4" target="_blank">{{ wy.text4 }}</a></li>
            <li><a :href="wy.a5" :title="wy.text5" target="_blank">{{ wy.text5 }}</a></li>

          </ul>
        </div>
      </div>
    </div>
    <div class="w">
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">汽车</a>
          </h3>
        </div>
        <div class="mainnb">

          <h3>
            <a :href="qc.a" target="_blank" :title="qc.text1">
              <img :src="qc.url"
                :alt="qc.text1"></a>
          </h3>
          <p>
            <a :href="qc.a1" target="_blank" :title="qc.text1">
              {{ qc.text1 }}</a>
          </p>

        </div>
        <div class="mainnc">
          <ul>

            <li> <a :href="qc.a2" target="_blank" :title="qc.text2">
              {{ qc.text2 }}</a></li>

            <li> <a :href="qc.a3" target="_blank" :title="qc.text3">
              {{ qc.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>

            <li> <a :href="qc.a4" target="_blank" :title="qc.text4">
              {{ qc.text4 }}</a></li>

            <li> <a :href="qc.a5" target="_blank" :title="qc.text5">
              {{ qc.text5 }}</a></li>

          </ul>
        </div>
      </div>
      <div class="mainn">
        <div class="mainna">
          <h3>
            <a href="" target="_blank">房产</a>
          </h3>
        </div>
        <div class="mainnb">
          <h3><a target="_blank" :href="fc.a"
              :title="fc.text1"><img :alt="fc.text1"
                :src="fc.url"></a></h3>
          <h4></h4>
          <p><a target="_blank" :href="fc.a1">{{ fc.text1 }}</a>
          </p>

        </div>
        <div class="mainnc">
          <ul>
            <li><a target="_blank" :href="fc.a2" :title="fc.text2">{{ fc.text2 }}</a></li>
            <li><a target="_blank" :href="fc.a3" :title="fc.text3">{{ fc.text3 }}</a></li>

          </ul>
        </div>
        <div class="mainnd">
          <ul>
            <li><a target="_blank" :href="fc.a4" :title="fc.text4">{{ fc.text4 }}</a>></li>
            <li><a target="_blank" :href="fc.a5" :title="fc.text5">{{ fc.text5 }}</a></li>

          </ul>
        </div>
      </div>
      <div class="mainq">
        <div class="mainqa">
          网上办事
        </div>
        <div class="mainqb">
          <ul>
            <li class="first"><a href="hhttps://wssp.hainan.gov.cn/hnwt/home" target="_blank">海南政务服务</a></li>
            <li><a href="http://hrss.hainan.gov.cn/" target="_blank">社保信息查询</a></li>
            <li class="first"><a href="https://www.hainan.gov.cn/" target="_blank">电子诉讼平台</a></li>
            <li><a href="https://www.hainan.gov.cn/" target="_blank">交通违章查询</a></li>
            <li class="first"><a href="https://www.hainan.gov.cn/" target="_blank">民生警务平台</a></li>
            <li><a href="https://www.hainan.gov.cn/" target="_blank">食安信息查询</a></li>
          </ul>
        </div>
        <div class="mainqc">
          <ul>
            <li class="first">
              <h3>
                <a href="http://www.open.sd-china.com/" target="_blank">
                  <img src="https://www.sdchina.com/images/zouxiangshijie.jpg"></a>
              </h3>
              <h4></h4>
              <p>
                <a href="http://www.open.sd-china.com" target="_blank">《走向世界》</a>
              </p>
            </li>
            <li>
              <h3>
                <a href="http://www.newair.cc/" target="_blank">
                  <img src="	https://www.sdchina.com/images/xinhangkong.jpg"></a>
              </h3>
              <h4></h4>
              <p>
                <a href="http://www.newair.cc/" target="_blank">《新航空》</a>
              </p>
            </li>
            <li>
              <h3>
                <a href="http://www.cn-kr.org/" target="_blank">
                  <img src="	https://www.sdchina.com/images/jinqiao.jpg
"></a>
              </h3>
              <h4></h4>
              <p>
                <a href="http://www.cn-kr.org/" target="_blank">《金桥》</a>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer">
  <div class="footlink">
    <ul>
      <li><a href="https://www.hainan.gov.cn/" target="_blank">首页</a></li>
      <li class="linj"></li>
      <li><a href="https://www.hainan.gov.cn/" target="_blank">办事指南</a></li>
      <li class="linj"></li>
      <li><a href="" target="_blank">联系我们</a></li>
      <li class="linj"></li>
      <li><a href="https://www.hainan.gov.cn/" target="_blank">用户注册</a></li>
      <li class="linj"></li>
      <li><a href="https://www.hainan.gov.cn/" target="_blank">交流中心</a></li>
      <li class="linj"></li>
      <li><a href="https://www.hainan.gov.cn/hainan/zfxxgk/newxxgk_index.shtml">信息公开</a></li>
      <li class="linj"></li>
      <li><a href="https://wssp.hainan.gov.cn/hnwt/home">政务服务</a></li>
    </ul>
  </div>
  <div class="footline"></div>
  <div class="footzg">
    <h3>中国网络集团 监制</h3>
    <p>技术支持：中网科技（海南）有限公司 琼ICP备2020003380号-21 琼公网安备 | 46010802000838号</p>
    <p>Copyright(C)2023 海南网 版权所有 </p>
    <!-- <a href="http://www.sdchina.com/"> -->
  </div>
  <div class="footpic">
    <ul>
     
    </ul>
  </div>
</div>
  </div>
</template>
  
<script>
// import { getsdgg } from '@/api/zwkj';
// import "element-ui/lib/theme-chalk/base.css";
// import Sldentify from '@/pages/siddentify/index.vue'
import Swiper from '../../components/Swiper.vue'
// import swipers from '../../components/Swipers.vue'
export default {
  name: "ShoYe",
  data() {
    return {


      inputvalue: '',
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      title:{},
      gg:{},
      lbttext:{},
      jrsd:{},
      sdgd:{},
      xwfb:{},
      yw:{},
      tj:{},
      zxt:{},
      eng:{},
      engt:{},
      cj:{},
      dwjl:{},
      jy:{},
      ly:{},
      wy:{},
      qc:{},
      fc:{}
    }
  },
  components: {
    Swiper

  },
  mounted() {
    this.getsdtitle()
    this.getsdgg()
    this.getsdlbttext()
    this.getsdsdgd()
    this.getsdjrsd()
    this.getsdxwfb()
    this.getsdyw()
    this.getsdtj()
    this.getsdzxt()
    this.getsdeng()
    this.getsdengt()
    this.getsdcj()
    this.getsddwjl()
    this.getsdjy()
    this.getsdly()
    this.getsdwy()
    this.getsdqc()
    this.getsdfc()


  }

  , methods: {
    async getsdfc(){
         const res=await this.$store.dispatch('getsdfc')
         this.fc={...res}
      },
    async getsdqc(){
         const res=await this.$store.dispatch('getsdqc')
         this.qc={...res}
      },
    async getsdwy(){
         const res=await this.$store.dispatch('getsdwy')
         this.wy={...res}
      },
    async getsdly(){
         const res=await this.$store.dispatch('getsdly')
         this.ly={...res}
      },
    async getsdjy(){
         const res=await this.$store.dispatch('getsdjy')
         this.jy={...res}
      },
    async getsddwjl(){
         const res=await this.$store.dispatch('getsddwjl')
         this.dwjl={...res}
      },
    async getsdcj(){
         const res=await this.$store.dispatch('getsdcj')
         this.cj={...res}
      },
    async getsdengt(){
         const res=await this.$store.dispatch('getsdengt')
         this.engt={...res}
      },
    async getsdeng(){
         const res=await this.$store.dispatch('getsdeng')
         this.eng={...res}
      },
    async getsdzxt(){
         const res=await this.$store.dispatch('getsdzxt')
         this.zxt={...res}
      },
    async getsdtj(){
         const res=await this.$store.dispatch('getsdtj')
         this.tj={...res}
      },
    async getsdyw(){
         const res=await this.$store.dispatch('getsdyw')
         this.yw={...res}
      },
    async getsdxwfb(){
         const res=await this.$store.dispatch('getsdxwfb')
         this.xwfb={...res}
      },
    async getsdsdgd(){
         const res=await this.$store.dispatch('getsdsdgd')
         this.sdgd={...res}
      },
    async getsdjrsd(){
         const res=await this.$store.dispatch('getsdjrsd')
         this.jrsd={...res}
      },
    async getsdlbttext(){
         const res=await this.$store.dispatch('getsdlbttext')
         this.lbttext={...res}
      },
      async getsdtitle(){
         const res=await this.$store.dispatch('getsdtitle')
         this.title={...res}
      },
      async getsdgg(){
         const res=await this.$store.dispatch('getsdgg')
         this.gg={...res}
      },

    showa() {
      this.a = 1
    }

  }
};
</script>
  
<style lang="css" scoped>
  .footer {
    overflow: hidden;
    width: 100%;
    background: #575757;
    margin: 30px auto 0px;
    padding: 18px 0px 25px;
}
.footlink {
    overflow: hidden;
    width: 1200px;
    height: 42px;
    margin: 0px auto;
}
.footlink ul li {
    float: left;
    overflow: hidden;
    font-size: 20px;
    color: #bcbcbc;
    text-align: center;
    width: 169px;
    height: 42px;
    line-height: 42px;
}
.footlink ul li.linj {
    width: 2px;
    /* background: url(../img/bj11.jpg) no-repeat 0px center; */
}
.footlink a {
    color: #bcbcbc;
}
.footlink a:hover{
  color: #fff;
}
.footzg a {
    color: #bcbcbc;
}
.footline {
    width: 100%;
    height: 22px;
    background: url(	https://static.sdchina.com/img/bj12.jpg) repeat-x 0px center;
}

.footzg {
    overflow: hidden;
    font-size: 14px;
    color: #bcbcbc;
    text-align: center;
    width: 1200px;
    margin: 0px auto;
}
.footzg h3 {
    font-size: 18px;
    font-weight: normal;
    line-height: 40px;
}
.footzg p {
    line-height: 24px;
}
.footpic {
    overflow: hidden;
    width: 760px;
    margin: 15px auto 0px;
}
.footpic ul li {
    float: left;
    overflow: hidden;
    display: inline;
    width: auto;
    height: 57px;
    margin-left: 15px;
}
.footer{
    overflow: hidden;
    width: 100%;
    background: #575757;
    margin: 30px auto 0px;
    padding: 18px 0px 25px;
}

.mainqc ul li h3 img {
    width: 108px;
    height: 142px;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.mainqc ul li h3 img:hover{
  transform: scale(1.05);
  opacity: 0.8;
}
.mainq {
    overflow: hidden;
    float: right;
    width: 342px;
}
.mainqa {
    overflow: hidden;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background: url(	https://static.sdchina.com/img/bj8.jpg
) no-repeat center center;
    width: 342px;
    line-height: 45px;
    margin: 15px auto 0px;
}
.mainqb {
    overflow: hidden;
    width: 342px;
}
.mainqb ul li.first {
    margin-left: 0px;
}
.mainqb ul li {
    float: left;
    overflow: hidden;
    background: url(	https://static.sdchina.com/img/bj9.jpg) no-repeat 0px 0px;
    width: 132px;
    height: 34px;
    line-height: 31px;
    margin: 10px 0px 0px 30px;
    padding-left: 24px;
}

.mainqc {
    overflow: hidden;
    width: 342px;
    margin: 15px auto 0px;
}
.mainqc ul li.first {
    margin-left: 0px;
}
.mainqc ul li {
    overflow: hidden;
    _display: inline;
    float: left;
    width: 108px;
    margin-left: 9px;
}

.mainp {
  overflow: hidden;
  float: right;
  width: 373px;
}

.mainpa {
  overflow: hidden;
  width: 373px;
  height: 42px;
  margin: 20px auto 0px;
}

.mainpa h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.mainpb {
  overflow: hidden;
  width: 373px;
  margin: 30px auto 0px;
}

.mainpb h3 {
  overflow: hidden;
  float: left;
  width: 190px;
  height: 126px;
  margin-right: 20px;
}

.mainpb p {
  overflow: hidden;
  vertical-align: middle;
  font-weight: bold;
  height: 126px;
  line-height: 32px;
}

.mainpc {
  overflow: hidden;
  width: 373px;
  margin: 15px auto 0px;
}

.mainpc ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj2.jpg
) no-repeat 0px center;
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainpd {
  overflow: hidden;
  width: 373px;
  margin: 0px auto;
}

.mainpd ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj3.jpg) no-repeat 0px center;
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}


.mainnb h3 img {
  width: 190px;
  height: 126px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mainpb h3 img {
  width: 190px;
  height: 126px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mainpb h3 img:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.mainnb h3 img:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.mainn {
  overflow: hidden;
  float: left;
  width: 383px;
  margin-right: 30px;

}

.mainna {
  overflow: hidden;
  width: 383px;
  height: 42px;
  margin: 20px auto 0px;
}

.mainna h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.mainnb {
  overflow: hidden;
  width: 383px;
  margin: 30px auto 0px;
}

.mainnb h3 {
  overflow: hidden;
  float: left;
  width: 190px;
  height: 126px;
  margin-right: 20px;
}

.mainnc {
  overflow: hidden;
  width: 383px;
  margin: 15px auto 0px;
}

.mainnc ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj2.jpg) no-repeat 0px center;
  width: 370px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainnd {
  overflow: hidden;
  width: 383px;
  margin: 0px auto;
}

.mainnd ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj3.jpg) no-repeat 0px center;
  width: 370px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainn {
  overflow: hidden;
  float: left;
  width: 383px;
  margin-right: 30px;
}

.mainna {
  overflow: hidden;
  width: 383px;
  height: 42px;
  margin: 20px auto 0px;
}

.mainna h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.mainnb {
  overflow: hidden;
  width: 383px;
  margin: 30px auto 0px;
}

.mainnb h3 {
  overflow: hidden;
  float: left;
  width: 190px;
  height: 126px;
  margin-right: 20px;
}

.mainnc {
  overflow: hidden;
  width: 383px;
  margin: 15px auto 0px;
}

.mainnc ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj2.jpg) no-repeat 0px center;
  width: 370px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainnd {
  overflow: hidden;
  width: 383px;
  margin: 0px auto;
}

.mainnd ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj3.jpg) no-repeat 0px center;
  width: 370px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.maino {
  overflow: hidden;
  float: right;
  width: 360px;
  background: url(https://static.sdchina.com/img/bj6.jpg) no-repeat 0px 0px;
  margin: 30px 0px 0px;
  padding: 3px 0px 0px;
  border: 1px solid #e4e3eb;
  border-top: none;
  height: 360px;
}

.mainoa {
  overflow: hidden;
  width: 331px;
  height: 60px;
  background: #ededed;
  margin: 0px auto;
  padding: 0px 20px;
}

.mainoa h3 {
  float: left;
  padding: 5px 0px 0px;
}

.mainob {
  overflow: hidden;
  width: 332px;
  margin: 0px auto;
  padding: 15px 0px 25px;
}

.mainob ul li {
  float: left;
  overflow: hidden;
  width: 332px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px dotted #d9dada;
}

.mainoa h4 {
  float: right;
  padding: 20px 0px 0px;
}


.eng {
  overflow: hidden;
  width: 100%;
  background: #f1f1f1;
  margin: 30px auto 0px;
  padding: 25px 0px;
}

.eng a:hover {
  color: rgb(163, 28, 97);
}

.enga {
  overflow: hidden;
  width: 1200px;
  height: 47px;
  background: url(https://static.sdchina.com/img/bj1.jpg
) repeat-x 0px bottom;
  margin: 0px auto;
}

.enga h3 {
  float: left;
  font-size: 35px;
  height: 45px;
  border-bottom: 2px solid #b42369;
}

.enga h4 {
  float: right;
  font-size: 21px;
  color: #676767;
  font-weight: normal;
  line-height: 47px;
}

.en {
  font-family: Georgia, Arial;
}

.engb {
  overflow: hidden;
  width: 1260px;
  margin: 30px auto 0px;
}

.engb ul li {
  /* display: flex; */
  overflow: hidden;
  float: left;
  width: 580px;
  background: #d5d5d5;
  margin-left: 30px;
  padding-right: 5px;
}

.engb ul li h3 {
  overflow: hidden;
  float: left;
  width: 360px;
  height: 240px;
  margin-right: 20px;
}

.engb ul li h3 img {
  width: 360px;
  height: 240px;

  transition: all 0.5s;
}

.engb ul li h3 img:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.engb ul li p {
  /* width: 200px; */
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  font-size: 21px;
  height: 240px;
  line-height: 32px;
  /* float: left; */
}

.engc {
  overflow: hidden;
  width: 1260px;
  /* margin: auto; */
  margin: 20px auto 0px;
}

.engc ul li {
  float: left;
  overflow: hidden;
  font-size: 18px;
  background: url(https://static.sdchina.com/img/bj2.jpg) no-repeat 0px center;
  width: 570px;
  height: 40px;
  line-height: 40px;
  margin-left: 30px;
  padding-left: 15px;
}

.engd {
  overflow: hidden;
  width: 1260px;
  margin: 0px auto;
}

.engd ul li {
  float: left;
  overflow: hidden;
  font-size: 18px;
  background: url(https://static.sdchina.com/img/bj3.jpg
) no-repeat 0px center;
  width: 570px;
  height: 40px;
  line-height: 40px;
  margin-left: 30px;
  padding-left: 15px;
}




.showa {
  left: -1200px;
  transition: all 0.5s;
}

.showa2 {
  left: 0px;
  transition: all 0.5s;
}

.showb {
  left: -1200px;
  transition: all 0.5s;
}

.showb2 {
  left: 0px;
  transition: all 0.5s;
}

.showc {
  right: -1200px;
  transition: all 0.5s;
}

.showc2 {
  right: 0px;
  transition: all 0.5s;
}

.showd {
  right: -1200px;
  transition: all 0.5s;
}

.showd2 {
  right: 0px;
  transition: all 0.5s;
}

/* .top100 {
 
} */

.top {
  font-size: 14px;
  color: #6e6e6e;
  width: 1200px;
  height: 35px;
  line-height: 35px;
  margin: 0px auto;
}

.top a:hover {
  color: rgb(163, 28, 97);
}

.tophome {
  float: left;
}

.toptxt1 {
  color: #a8a8a8;
  padding: 0px 8px;
}

.topapp {
  float: left;
  background: url(https://static.sdchina.com/img/gz.jpg) no-repeat 35px center;
  padding-left: 62px;
}

.topmobile {
  float: left;
  padding-left: 35px;
}

.login {
  float: right;
}

.phone {
  float: right;
  padding-right: 35px;
}

.search {
  float: right;
  background: url(https://static.sdchina.com/img/search.jpg
) no-repeat 0px center;
  width: 86px;
}

.search:hover .searchb {
  display: block;
}

ul,
li {
  list-style: none;
}

.searcha {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-indent: 22px;
}

.searchb {
  display: none;
  position: absolute;
  overflow: hidden;
  width: 310px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
}

.intex2 {
  overflow: hidden;
  font-size: 14px;
  color: #000;
  background: #fff;
  width: 230px;
  height: 45px;
  line-height: 45px;
  padding: 0px 10px;
  border: 0;
  outline: none;
}

.intex3 {
  /* -webkit-appearance: none; */
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  text-indent: -999px;
  background: url(https://static.sdchina.com/img/bj15.jpg) no-repeat 0px 0px;
  width: 60px;
  height: 45px;
  line-height: 45px;
  border: 0;
  outline: none;
}

a {
  color: #6e6e6e;
  list-style: none;
  text-decoration: none;
}

.header {
  overflow: hidden;
  width: 1200px;
  margin: 0px auto;
  padding: 15px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  overflow: hidden;
  float: left;
}

.language {
  overflow: hidden;
  float: right;
  font-size: 26px;
  color: #e0e0e0;
  height: 45px;
  line-height: 45px;
}

/* language */
.language a {
  font-weight: bold;
  padding: 0px 12px;
  color: #333333;
}

.language a:hover {
  color: rgb(163, 28, 97);
}

.enn {
  font-family: Arial;
}

.kr {
  font-family: Dotum, FangSong, 궁서, Gulim;
  font-weight: bold;
}

.language a.last {
  padding-right: 0px;
}

.jp {
  font-family: ＤＦＧ華康明朝体, SimHei, MS PGothic;
}

.wnd {
  overflow: hidden;
  width: 1178px;
  margin: 15px auto 0px;
  padding: 10px 5px 10px 15px;
  border: 1px solid #dedede;
}

.wnda {
  overflow: hidden;
  float: left;
  width: 140px;
}

.wnda ul li {
  float: left;
  overflow: hidden;
  font-size: 15px;
  width: 140px;
  height: 28px;
  line-height: 28px;
}

.wndb {
  overflow: hidden;
  float: left;
  width: 850px;
  margin-left: 15px;
}

.wnd a:hover {
  color: rgb(163, 28, 97);
}

.wndb2 {
  overflow: hidden;
  width: 850px;
  margin: auto 0px;
}

.ads-box {
  position: relative;
}

* {
  margin: 0px;
  padding: 0px;
}

.ads-box>div,
.ads-box-no>div {
  margin-bottom: 15px;
  position: relative;
}

.wndb1 {
  overflow: hidden;
  font-size: 15px;
  text-align: left;
  width: 850px;
  height: 28px;
  line-height: 28px;
  margin: auto 0px;
}

.wndc {
  float: right;
  width: 158px;
}

.wndc ul li.first {
  height: 77px;
  margin: 0px 0px;
}

.wndc ul li {
  margin-left: -67px;
  float: left;
  overflow: hidden;
  font-size: 15px;
  width: 220px;
  height: 28px;
  line-height: 28px;
  margin-top: 7px;
}

.wndb1 a {
  padding: 0px 22px;
}

.wndb1 {
  overflow: hidden;
  font-size: 15px;
  text-align: left;
  width: 850px;
  height: 28px;
  line-height: 20px;
  margin: auto 0px;
}

.menu {
  overflow: hidden;
  width: 1200px;
  margin: 15px auto 0px;
  margin-bottom: 20px;
}

.menu ul li {
  float: left;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 4px;
  width: 88px;
  height: 56px;
  line-height: 28px;
}

.menu ul li a {
  color: #333333;
}

.menu ul li.line {
  width: 1px;
  background: url(https://static.sdchina.com/img/bj7.jpg) no-repeat 0px center;
}

.menu ul li.four {
  width: 124px;
}

.menu ul li.five {
  width: 150px;
}

.menu ul li.last {
  float: right;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  text-align: left;
  width: 400px;
}

.menu ul li.last a {
  padding-left: 18px;
}

.menu ul li.first {
  width: 62px;
  text-align: left;
}

.menu a:hover {
  color: rgb(163, 28, 97);
}

.title {
  text-align: center;
  overflow: hidden;
  width: 1200px;
  background: #f6f6f6;
  margin: 30px auto 0px;
  padding: 20px 0px 15px;
}

.title a:hover {
  color: rgb(163, 28, 97);
}

.title p a {
  margin: 0px 10px;
}

.title span {
  color: #a8a8a8;
}

.title p {
  font-size: 18px;
  color: #666;
  text-align: center;
  line-height: 35px;
  margin-top: 20px;
}

h3 a {
  color: #333333;
}

.w {
  overflow: hidden;
  width: 1200px;
  margin: 0px auto;
}

.maina {
  overflow: hidden;
  float: left;
  width: 720px;
}

.focus {
  overflow: hidden;
  position: relative;
  width: 690px;
  height: 460px;
  margin: 30px 0px 0px;
}

.focus {
  overflow: hidden;
  position: relative;
  width: 690px;
  height: 460px;
  margin: 30px 0px 0px;
}

.mainab {
  overflow: hidden;
  width: 720px;
  margin: 20px auto 0px;
}

.mainab ul li {
  overflow: hidden;
  float: left;
  width: 320px;
  margin-right: 30px;
  padding-right: 8px;
  border: 1px solid #dcdcdc;
}

.mainab ul li h3 {
  overflow: hidden;
  float: left;
  width: 166px;
  height: 110px;
  margin-right: 15px;
}

.mainab ul li h3 img {
  width: 166px;
  height: 110px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mainab ul li p {
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  height: 110px;
  line-height: 30px;
}

.mainab ul li p a:hover {
  color: rgb(163, 28, 97);
}

.mainab ul li h3 img:hover {
  transform: scale(1.05);
  opacity: 0.8;

}

.mainb {
  overflow: hidden;
  float: right;
  width: 480px;
  margin: 20px 0px 0px;
}

.mainb a {
  color: #333333;
}

.mainb a:hover {
  color: rgb(163, 28, 97);
}

.mainb h2 {
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
}

.mainb h3 {
  overflow: hidden;
  font-size: 18px;
  font-weight: normal;
  background: url(https://static.sdchina.com/img/bj2.jpg
) no-repeat 0px center;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
}

.mainb h4 {
  overflow: hidden;
  font-size: 18px;
  font-weight: normal;
  background: url(https://static.sdchina.com/img/bj3.jpg
) no-repeat 0px center;
  width: 466px;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
}

.mainb p {
  overflow: hidden;
  font-size: 18px;
  background: url(https://static.sdchina.com/img/bj4.jpg
) no-repeat 0px center;
  width: 466px;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
}

.mainb h5 {
  overflow: hidden;
  height: 7px;
  margin: 14px 0px 0px;
  border-top: 1px dotted #bfbfbf;
}

.bg2 {
  overflow: hidden;
  width: 100%;
  background: #f1f1f1;
  margin: 30px auto 0px;
  padding: 30px 0px;
}

.w {
  overflow: hidden;
  width: 1200px;
  margin: 0px auto;
}

.mainc {
  overflow: hidden;
  float: left;
  width: 373px;
  margin-right: 30px;
}

.mainca {
  overflow: hidden;
  width: 373px;
  height: 42px;
  background: url(https://static.sdchina.com/img/bj1.jpg
) repeat-x 0px bottom;
  margin: 0px auto;
}

.mainca h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.maincb {
  overflow: hidden;
  width: 373px;
  margin: 20px auto 0px;
}

.maincb ul li {
  float: left;
  overflow: hidden;
  /* background: url(../img/bj2.jpg) no-repeat 0px center; */
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.maincc {
  overflow: hidden;
  width: 373px;
  margin: 20px auto 0px;
}

.maincc ul li {
  float: left;
  overflow: hidden;
  /* background: url(../img/bj3.jpg) no-repeat 0px center; */
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.maind {
  overflow: hidden;
  float: right;
  width: 394px;
}

.mainda {
  overflow: hidden;
  width: 394px;
  margin: 0px auto;
}

.mainda img {
  display: block;
}

.maindb {
  overflow: hidden;
  width: 352px;
  margin: 0px auto;
  padding: 10px 20px 10px;
  border: 1px solid #90c8da;
  border-top: none;
}

.maindb ul li {
  float: left;
  overflow: hidden;
  /* background: url(../img/bj13.jpg) no-repeat 0px center; */
  width: 337px;
  height: 38px;
  line-height: 38px;
  padding-left: 15px;
}

.maindb ul li {
  float: left;
  overflow: hidden;
  /* background: url(../img/bj13.jpg) no-repeat 0px center; */
  width: 337px;
  height: 38px;
  line-height: 38px;
  padding-left: 15px;
}

.maindc {
  overflow: hidden;
  width: 392px;
  background: #f5f5f5;
  margin: 10px auto 0px;
  padding: 15px 0px 8px;
  border: 1px solid #d5d5d5;
}

.maindc ul li {
  overflow: hidden;
  float: left;
  width: 196px;
  text-align: center;
}

.maindc ul li h3 {
  overflow: hidden;
  width: 120px;
  margin: 0px auto;
}

.maindc ul li h3 img {
  width: 120px;
}

.maindc ul li p {
  overflow: hidden;
  font-size: 16px;
  line-height: 30px;
}

.maine {
  overflow: hidden;
  float: left;
  width: 776px;
}

.mainee {
  overflow: hidden;
  float: left;
  width: 806px;
  height: 42px;
  background: url(https://static.sdchina.com/img/bj1.jpg) repeat-x 0px bottom;
  margin: 30px auto 0px;
}

.mainee h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.mainea {
  overflow: hidden;
  float: left;
  width: 373px;
  margin: 35px 30px 27px 0px;
}

.mainea h3 {
  overflow: hidden;
  float: left;
  width: 192px;
  height: 128px;
  margin-right: 20px;
}

.mainea p {
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  height: 128px;
  line-height: 32px;
}

.maineb {
  overflow: hidden;
  float: left;
  width: 373px;
  margin: 20px 0px 10px;
}

.maineb ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj2.jpg) no-repeat 0px center;
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainec {
  overflow: hidden;
  float: left;
  width: 373px;
  margin-right: 30px;
}

.mainec ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj3.jpg) no-repeat 0px center;
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mained {
  overflow: hidden;
  float: left;
  width: 373px;
}

.mained ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj4.jpg) no-repeat 0px center;
  width: 360px;
  height: 40px;
  line-height: 40px;
  padding-left: 13px;
}

.mainf {
  overflow: hidden;
  float: right;
  width: 394px;
}

.mainfa {
  overflow: hidden;
  width: 394px;
  height: 42px;
  background: url(https://static.sdchina.com/img/bj1.jpg) repeat-x 0px bottom;
  margin: 30px auto 0px;
}

.mainfa h3 {
  float: left;
  font-size: 24px;
  height: 40px;
  border-bottom: 2px solid #b42369;
}

.mainfb {
  overflow: hidden;
  position: relative;
  width: 394px;
  margin: 29px auto 0px;
}

.mainfbb {
  position: relative;
  float: left;
  overflow: hidden;
  width: 424px;
}

.mainfbb ul li {
  _display: inline;
  overflow: hidden;
  float: left;
  width: 182px;
  height: 266px;
  margin: 0px 30px 0px 0px;
}

.mainfc {
  overflow: hidden;
  width: 394px;
  margin: 10px auto 0px;
}

.mainfc ul li {
  float: left;
  overflow: hidden;
  background: url(https://static.sdchina.com/img/bj2.jpg
) no-repeat 0px center;
  width: 380px;
  height: 40px;
  line-height: 40px;
  padding-left: 14px;
}

.mainfbb ul li img {
  width: 182px;
  height: 266px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mainfbb ul li img:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.mainea h3 img {
  width: 192px;
  height: 128px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mainea h3 img:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

.bg2 a:hover {
  color: #b42369;
}

.w a:hover {
  color: #b42369;
}</style>

  